export const environment = {
  production: false,
  parrotApiBaseUrl: 'https://hub-staging.storyshaper.io/parrot_subscription', // if run using 'start dotnet watch run'
  apiBaseUrl: 'https://hub-staging.storyshaper.io/mif_subscription',
  authServerUrl: 'https://hub-staging.storyshaper.io/green',
  clientSecret: 'd7a3ddf0-2253-403f-bed8-720ec119e38e',
  clientId: 'mif_subscription',
  orgId: 'peri',
  enableABTesting: false,
  useABTestingStagingURL: false
};
